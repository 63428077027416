import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import ImageTween from "../components/imageTween"

const UnityEye = () => {
  const lang = "en"

  return (
    <Layout lang={lang} translation="/pl/unity-eye/">
      <SEO title="UNITY Eye" />

      <section className="home-top">
        <div className="global__padd">
          <div className="home-top__wrapper">
            <h2>
              UNITY <br /> <span>Centre</span>
            </h2>
            <h2>Krakow's Business Complex</h2>
          </div>
          <ImageTween
            src={require("../assets/images/unity-eye-top-image.jpg")}
            srcSet={require("../assets/images/unity-eye-top-image@2x.jpg")}
            alt=""
          />
        </div>
      </section>

      <section className="unity-eye-top global__padd--bigger">
        <h2>UNITY Eye</h2>
        <p>
          Krakow’s highest and best view is situated at the top of the UNITY
          Tower, on the 26th floor. Rise up 102 meters above the ground in a
          mere 45 seconds! The panoramic terrace offers visitors unparalleled
          360-degree views up even to Tatra Mountains.
        </p>
        <div className="global-section-tiles__tile-soon">
          <span>Coming soon…</span>
        </div>
      </section>
    </Layout>
  )
}

export default UnityEye
